import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';
import QRCodeTypeEnum from '@/modules/qr-codes/enums/QRCodeTypeEnum';
import IndexableInterface from '@/core/interfaces/IndexableInterface';
import { formatDate, formatDateTime } from '@/core/helpers/utils/DateUtils';

export default class QRCodeModel extends ORMModelExtended {
  /**
   * ORM entity
   */
  public static entity = 'qr-codes';

  /**
   * API method conf
   */
  public static apiConfig = {
    actions: {
      get: {
        method: 'GET',
        url: '/v2/qr-codes/encrypted/:id',
      },
      update: {
        method: 'PATCH',
        url: '/v2/qr-codes/use/:id',
      },
    },
  };

  public static fields() {
    return {
      id: this.string(''),
      title: this.string(''),
      type: this.string(''),
      content: this.attr([]),

      validStartingAt: this.string('').nullable(),
      expiresAt: this.string('').nullable(),
      usedAt: this.string('').nullable(),

      isValid: this.boolean(false),
      isExpired: this.boolean(false),
      isUsed: this.boolean(false),
      isActive: this.boolean(false),

      createdAt: this.string('').nullable(),
      updatedAt: this.string('').nullable(),
    };
  }

  /**
   * Public fields
   */
  public id!: string;
  public title!: string;
  public type!: QRCodeTypeEnum;
  public content!: any;

  public validStartingAt!: string | null;
  public expiresAt!: string | null;
  public usedAt!: string | null;

  public isValid!: boolean;
  public isExpired!: boolean;
  public isUsed!: boolean;
  public isActive!: boolean;

  public createdAt!: string;
  public updatedAt!: string;

  /**
   * Get QR Code processed content
   */
  public getProcessedContent(): IndexableInterface[] {
    let content: IndexableInterface[] = [];

    if (this.isOnlineOrderType) {
      content = this.getOnlineOrderTypeProcessedContent();
    }

    if (this.validStartingAt) {
      content = [
        ...content,
        {
          label: 'Ważny od', // TODO translate
          value: formatDate(this.validStartingAt),
        },
      ];
    }

    if (this.usedAt) {
      content = [
        ...content,
        {
          label: 'Wykorzystano', // TODO translate
          value: formatDateTime(this.usedAt),
        },
      ];
    } else if (this.expiresAt) {
      content = [
        ...content,
        {
          label: 'Wygasa', // TODO translate
          value: formatDate(this.expiresAt),
        },
      ];
    }

    return content;
  }

  /**
   * Check if QR code has image.
   */
  public hasImage(): boolean {
    return !!this.getImage();
  }

  /**
   * Get QR code image.
   */
  public getImage(): string | null {
    if (this.isOnlineOrderType) {
      return this.content.product.thumbPath;
    }

    return null;
  }

  /**
   * Check if QR code is Online Order type.
   */
  public get isOnlineOrderType(): boolean {
    return this.isType(QRCodeTypeEnum.ONLINE_ORDER);
  }

  /**
   * Check if QR code is given type.
   *
   * @param type
   */
  public isType(type: QRCodeTypeEnum) {
    return this.type === type;
  }

  /**
   * Get Online Order QR Code processed content
   */
  private getOnlineOrderTypeProcessedContent() {
    return [
      {
        label: 'Nr zamówienia',
        value: this.content.onlineOrder.number,
      },
    ];
  }
}
